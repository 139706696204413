:root {
	--color-primary-light: #3c65d6;
	--color-primary-dark: #4574f5;
	--color-accent-light: #e5ae40;
	--color-accent-dark: #f5c462;
	--color-danger-dark: #f56262;
	--color-bg-100-light: #ffffff;
	--color-bg-90-light: #f0f4ff;
	--color-bg-80-light: #e6e6e6;
	--color-bg-100-dark: #0d0e0f;
	--color-bg-90-dark: #1b1c1f;
	--color-bg-80-dark: #282a2e;

	--color-text-80-light: #464c5c;
	--color-text-50-light: #8c97b8;
	--color-text-60-light: #747e99;
	--color-text-100-light: #17191f;
	--color-text-90-light: #2f323d;
	--color-text-70-light: #5d657a;
	--color-text-40-light: #a3b1d6;

	--color-primary-light-hover: #0051cc0f;
	--color-input-border-light: #d7dff5;
	--color-input-border-focus-light: #3c65d6;
	--color-input-border-dark: #36383d;
	--color-input-border-focus-dark: #a2a7b8;
	--color-input-background: #f5f8ff;
	--color-delete: #ef4388;
	--color-orange: #efb643;
	--color-table-header: #f5f8ff;
	--color-table-header-text: #1a4f9e;

	--color-label-dark: #6c707a;

	--color-header-label-dark: #1f1f1f;

	--color-white-border: #ebeef5;
	--color-success: #4aaf54;
	--color-green: #33b87a;

	--color-error: #f56262;
	--color-black: #000000;
	--color-bg-modal: #ebeef5;
	--color-cancel-btn: #51545c;
	--color-bg-cancel-btn: hsla(224, 6%, 23%, 0.06);
	--color-transparent: transparent;
	--color-text-modal: #3d3d3d;
	--color-bg-copy: #7a7a7a;
	--color-input-border-light-1: #ced2de;
	--color-water-border: #c8e1f8;
	--color-outer-space-border: #3f4b5b;
	--color-waldon-blue-border: #819abb;
	--color-dodger-blue: #1e90ff;
	--color-gray-dark: #111111;
	--color-gray-very-dark: #222222;
	--color-charcoal-dark: #333333;
	--color-gray-medium-dark: #444444;
	--color-gray-medium-light: #999999;
	--color-dark-pastel-blue: #81aace;
	--color-beau-blue: #b9d2e8;
	--color-beau-blue: #b9d2e8;
	--color-tart-orange: #f54545;
	--color-lavender-gray: #bcc3d6;
	--color-darkgunmetal: #212529;
	--color-davy-grey: #565656;
	--color-cultured: #f7f7f7;
	--color-light-orange: #e88a2c;
	--color-muted-grayish-blue: #515767;
	--color-tangerine-Orange: #e88a2c1f;

	// border-radius
	--border-radius: 16px;

	// margin
	--margin: 1rem;
	--margin-top-1: 10px;

	//padding

	--padding: 16px;
	// font-size
	--font-size-1: 10px;
	--font-size-2: 12px;
	--font-size-3: 14px;
	--font-size-4: 16px;
	--font-size-5: 20px;
	--font-size-6: 24px;
	--font-size-7: 32px;

	// line-height

	--line-height-1: 0.625rem; // for 10px font size
	--line-height-2: 1rem; // for 12px font size
	--line-height-3: 1.25rem; // for 14px font size
	--line-height-4: 1.5rem; // for 16px font size
	--line-height-5: 2rem; // for 20px font size
	--line-height-6: 2.5rem; // for 24px font size
}
