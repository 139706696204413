.palmDetail--container {
	display: flex;
	padding: 16px 0px;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	flex: 1 0 0;
	align-self: stretch;

	&__content {
		flex: 1;
		padding-right: 20px;
		padding-left: 30px;
		max-width: 600px;

		&__heading {
			color: #000;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			margin-bottom: 3%;
		}

		&__subheading {
			color: var(--color-text-light-80, #515767);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
		}
	}

	&__imageinfo {
		width: 300px;
		height: 300px;
		margin: 0 auto 15px;
		object-fit: cover;
		transform: scaleX(-1);
	}
	@media screen and (max-width: 500px) {
		&__imageinfo {
			transform: none;
		}
	}
	@media screen and (max-width: 375px) {
		&__imageinfo {
			width: 250px;
			height: 250px;
			margin: 0 auto 15px;
		}
	}
}

.palmDetail__button--info {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
	.button {
		width: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.palmDetail__button--sticky {
	background-color: white;
	padding: 4px;
	position: sticky;
	bottom: 0;
	border: none;
}

@media screen and (max-width: 420px) {
	.palmDetail__button--info {
		display: flex;
		justify-content: center;
	}
}
