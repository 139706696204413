@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ivar+Display:wght@400;500;600;700&family=Source+Sans+Pro:wght@400;500;600;700&display=swap');

@import 'constants/scss/variables/variables';

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.iframe-container {
	box-shadow: none !important;
}

.pac-logo:after {
	content: '';
	padding: 0 px !important;
	height: 0px !important;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	text-align: right;
	display: block;
	background-image: none !important;
	background-position: right;
	background-repeat: no-repeat;
	-moz-background-size: 120px 14px;
	background-size: 0px !important;
}

.ppl-screen-body,
.ppl-screen-body__body {
	gap: 0px !important;
	height: 100%;
	overflow: auto;
	@media screen and (max-width: 768px) {
		height: calc(100% - 40px);
	}
}
.ppl-screen-body {
	&__overflow {
		overflow: hidden;
	}
}
.terms-body-wrapper {
	height: auto;
}

.terms_header {
	width: 100%;
}

.ppl-screen-body__label-container {
	border-radius: 16px 16px 0px 0px;
}

@media screen and (min-width: 500px) {
	.terms_header {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		// border-top: 2px solid hsla(0, 0, 0, 0.18);
		// border-right: 2px solid hsla(0, 0, 0, 0.18);
		// border-left: 2px solid hsla(0, 0, 0, 0.18);
		border-radius: 6px 6px 0px 0px;
		position: relative;
		z-index: 999;
		&_container {
			border-radius: 6px 6px 0px 0px;
			position: relative;
			height: 100%;
			z-index: 999;
		}
	}
}

.choose-option {
	width: 100%;
}

.iframe-wrapper {
	width: 100%;
	// height: calc(100% - 122px);
	height: fit-content;
	overflow: hidden;
	@media screen and (min-width: 500px) {
		max-width: 768px;
	}

	&__iframe-tag {
		height: 100%;
		width: 100%;
		border: none;
		@media screen and (min-width: 500px) {
			max-width: 768px;
		}
	}
	&__none {
		display: none;
	}
	&__block {
		display: block;
	}
}
.sv-tagbox__item {
	height: auto;
}

.d-flex {
	display: flex;
}
.align-center {
	align-items: center;
}

.align-start {
	align-items: start;
}

.gp-8 {
	gap: 8px;
}

.gp-4 {
	gap: 8px;
}

.direction-col {
	flex-direction: column;
}

// changes for brand color
input[type='radio']:checked {
	accent-color: var(--color-primary-light);
}

input[type='checkbox']:checked {
	accent-color: var(--color-primary-light);
}
