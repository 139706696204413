.PalmLivenessInformation--container {
	display: flex;
	padding: 16px 0px;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	flex: 1 0 0;
	align-self: stretch;

	&__content {
		flex: 1;
		padding-right: 20px;
		padding-left: 30px;
		max-width: 600px;

		&__heading {
			color: #000;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 50px;
			@media screen and (max-width: 480px) {
				line-height: 30px;
			}
		}

		&__subheading {
			color: var(--color-text-light-80, #515767);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			@media screen and (max-width: 480px) {
				line-height: 20px;
				margin-bottom: 2%;
			}
		}

		&__list {
			padding-left: 20px;
			&__list-item {
				list-style-type: disc;
				color: var(--color-text-light-80, #515767);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}
		}
	}
	&__selectHand-type-wrapper {
		display: flex;
		width: 100%;
		justify-content: center;
		gap: 8px;
		margin-bottom: 24px;
	}

	&__imageinfo {
		width: 300px;
		height: 36vh;
		margin: 0 auto 15px;
		object-fit: cover;
	}
	@media screen and (max-width: 420px) {
		&__imageinfo {
			width: 250px;
			height: 250px;
			margin: 0 auto 15px;
		}
	}
}

.PalmLivenessInformation__button-begin-liveness {
	background-color: white;
	padding: 4px;
	position: sticky;
	bottom: 0;
	border: none;
}
.PalmLivenessInformation__button--info {
	display: flex;
	justify-content: center;
	.button {
		width: 300px;
	}
}
.select-hand-box {
	padding: 4px;
	display: flex;
	gap: 8px;
	width: 40%;
}

.select-hand--card {
	position: relative;
	width: calc(50% - 4px);
	padding: 16px 8px;
	border-radius: 8px;
	border: 2px solid #dfe3ec;
	text-align: center;
	cursor: pointer;
	&__heading {
		font-size: 16px;
		line-height: 34px;
		font-weight: 600;
	}
}
.active-palm-type {
	border: 2px solid var(--color-primary-light);
}
.selected-palm-hand {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-40%, 50%);
	background-color: var(--color-primary-light);
	color: white;
	font-size: 10px;
	padding: 4px 8px;
	font-weight: 600;
	border-radius: 4px;
}
.palm-hand-type-image {
	min-height: 112px;
}

@media screen and (max-width: 420px) {
	.PalmLivenessInformation__button--info {
		display: flex;
		justify-content: center;
		.button {
			width: 100%;
		}
	}
}

.FacialLoader {
	height: calc(100vh - 100px);
	margin: auto;
}

@media screen and (max-width: 780px) {
	.select-hand-box {
		width: 100%;
	}
}
