.Event__Ticket {
	margin-bottom: 10px;
	&--header {
		padding: 24px 16px;
		font-weight: 600;
		border-bottom: 1px solid #0000001f;
	}
	&--container {
		padding: 16px;
		overflow: hidden;
		@media screen and (max-width: 576px) {
			padding-bottom: 100px;
		}
		&_outline {
			border: 1px solid #0000001f;
			padding: 16px;
			border-radius: 16px;
		}
		&_header {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 4px;
		}
		&_eventName {
			min-width: 200px;
			font-size: 20px;
			font-weight: 600;
			line-height: 28px;
		}
		&_eventDetails {
			display: flex;
			align-items: center;
			gap: 8px;
			span {
				display: flex;
				align-items: center;
				gap: 4px;
				color: #515767;
				font-size: 14px;
			}
		}
		&_body {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 12px;
		}
		&_footer {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
		&_address {
			display: flex;
			gap: 4px;

			div {
				p:first-child {
					font-weight: 600;
					line-height: 24px;
				}
				p:last-child {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
		&_seatDetails {
			display: flex;
			justify-content: space-between;

			div {
				text-align: center;
				border-right: 1px dashed #0000001f;
				padding: 0 12px;
				width: 50%;

				&:last-child {
					border: none;
				}
				span {
					font-size: 14px;
					line-height: 20px;
					color: #515767;
				}
				p {
					font-size: 20px;
					font-weight: 600;
					line-height: 28px;
				}
			}
		}
	}
	&--qr {
		height: 200px;
		width: 200px;
		background-color: #0000000f;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			height: 180px;
			width: 180px;
		}
	}
	&--ticketDetails {
		text-align: center;
		&_title {
			font-size: 14px;
			line-height: 20px;
		}
		&_number {
			font-size: 16px;
			line-height: 24px;
			font-weight: 600;
		}
	}
	&--circle-fill {
		display: inline-block;
		height: 6px;
		width: 6px;
		border-radius: 8px;
		background-color: #8d93a5;
	}

	&--hr {
		display: flex;
		align-items: center;
		padding: 24px 0;
		&-line {
			display: inline-block;
			height: 1px;
			width: 100%;
			border: 1px dashed #0000001f;
			position: relative;
			&::before {
				content: '';
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 0;
				height: 32px;
				width: 32px;
				border-radius: 50%;
				transform: translate(-100%, -50%) rotate(45deg);
				border: 1px solid;
				border-color: #0000001f #0000001f transparent transparent;
				background: white;
			}
			&::after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 0;
				height: 32px;
				width: 32px;
				border-radius: 50%;
				transform: translate(100%, -50%) rotate(45deg);
				border: 1px solid;
				border-color: transparent transparent #0000001f #0000001f;
				background: white;
			}
		}
	}
	&--footer {
		margin-top: 20px;
		background-color: #ffffff;
		padding: 24px 16px;
		width: 100%;
		bottom: 0;
		@media screen and (max-width: 576px) {
			position: fixed;
		}
	}

	span {
		i {
			font-size: 20px;
			color: #515767;
		}
	}
}
