.kyb-details {
	&__header {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding-bottom: 10px;
		&__title {
			font-weight: 600;
			font-size: var(--font-size-5);
			line-height: calc(var(--line-height-2) * 1.75);
			color: var(--color-text-90-light);
			letter-spacing: 0;
		}
		&__sub-title {
			font-weight: 400;
			font-size: var(--font-size-2);
			line-height: 16px;
			color: var(--color-text-70-light);
		}
		.BetterPhotoTips__tips-container {
			display: grid !important;
			margin-top: 4px;
		}
	}
	&--container {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 16px;
		&--btn {
			position: sticky;
			bottom: 0;
			opacity: 1;
		}
	}
	&--col-1 {
		grid-column: span 2 / span 2;
	}
	&__dropdown {
		&__wrapper {
			display: flex;
			align-items: center;
			gap: 16px;
		}
		&__container {
			display: flex;
			flex-direction: column;
			gap: 4px;
			width: 100%;
			grid-column: span 2 / span 2;
		}
		&__label {
			color: var(--color-text-70-light);
			font-size: 14px;
			font-weight: 500;
		}
	}
}

.kyb__submit--button {
	left: unset !important;
	right: unset !important;
	@media screen and (max-width: 768px) {
		position: fixed;
		padding: 10px 0;
		left: 8px !important;
		bottom: 0px;
		background-color: var(--color-white);
		width: calc(100% - 16px);
	}
}
.input-fields {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 80px;
	@media screen and (min-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
	}
	.input {
		height: 100% !important;
	}
	input[type='date'] {
		border: none;
		box-sizing: border-box;
		outline: 0;
		padding: 0.75rem;
		position: relative !important;
		width: 100%;
		&::-webkit-calendar-picker-indicator {
			background: transparent;
			bottom: 0;
			color: transparent;
			cursor: pointer;
			height: auto;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
		}
	}

	input[type='date']:before {
		content: none !important;
		background: transparent !important;
	}
}
.kyb-button-wrapper {
	display: flex;
	gap: 8px;
	justify-content: center;
	padding: 8px 0px;
	@media screen and (max-width: 768px) {
		position: fixed;
		bottom: 0;
		background-color: white;
		width: 100%;
		//TODO avinashSatschel need to add left0
		padding-right: 25px;
	}
}

.Get_Country-Btn--top-space {
	margin-top: 80px;
}
