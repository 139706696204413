.accred-wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	height: 100%;
	position: relative;
}

.upload-document {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	overflow-y: auto;
	&__description {
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		color: #8c97b8;
	}
	&__inner {
		position: relative;
		background-color: var(--color-bg-90-light);
		border: 1px dashed #cad1e6;
		border-radius: 8px;
		width: 100%;
		min-height: 200px;
		display: grid;
		place-items: center;
		&__details {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			width: 100%;
			label {
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				color: #464c5c;
				span {
					color: var(--color-input-border-focus-light);
				}
			}
			.info {
				font-weight: 500;
				font-size: 12px;
				line-height: 20px;
				color: #8c97b8;
			}
			&__file-upload {
				position: absolute;
				top: 0;
				display: block !important;
				height: 100%;
				width: 100%;
				opacity: 0;
				cursor: pointer;
			}
		}
	}
}

.uploaded-lists-wrapper {
	overflow-y: auto;
	padding-bottom: 100px;
	@media screen and (min-width: 768px) {
		padding-bottom: 142px;
	}
}

.uploaded-lists {
	height: 50px;
	width: 100%;
	border-bottom: 1px solid var(--color-input-border-light);
	display: flex;
	&__items {
		padding: 0 calc(var(--padding) * 0.5);
		display: flex;
		align-items: center;
		width: 100%;
		gap: 16px;

		&__listname {
			flex: 1 1;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			color: #2f323d;
			overflow: hidden;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.choose-option {
	&__item {
		padding: 8px;
		display: flex;
		align-items: center;
		gap: calc(var(--margin) * 0.5);
		width: 100%;
		height: 60px;
		border-bottom: 1px solid var(--color-input-border-light);
		cursor: pointer;
		&:hover {
			background-color: var(--color-hover-light-100);
			transition: 0.3s ease-in all;
			border-radius: 8px;
			cursor: pointer;
		}
		&__center {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
		&__title {
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			color: #747e99;
		}
		&__sub-title {
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #2f323d;
		}
	}
}

.accred-form {
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 100%;
	&__inputs {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 20px;
	}
}

.accred-header {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
	&__title {
		font-weight: 600;
		font-size: var(--font-size-5);
		line-height: calc(var(--line-height-2) * 1.75);
		color: var(--color-text-90-light);
		letter-spacing: 0;
	}
	&__sub-title {
		font-weight: 400;
		font-size: var(--font-size-2);
		line-height: 16px;
		color: var(--color-text-70-light);

		a {
			text-decoration: none;
			color: var(--color-primary-light);
			cursor: pointer;
		}
	}
}
.radio-select-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	margin-bottom: 180px;
}

.button-wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;

	position: absolute;
	background-color: var(--white);
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0%);
	padding: 0px var(--padding);
	padding-bottom: 8px;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.skip-now {
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: #d58888;
	border: 1px solid #d58888;
	padding: 4px 8px;
	border-radius: 8px;
}
/**
* simplici sign fixes css for accreditation
*/

.accred-wrapper {
	.iframe-wrapper {
		height: fit-content;

		.esign-signing-screen__container {
			height: calc(100vh - 85px) !important;
		}

		.esign-signing-screen__tnc-container {
			.terms-condition {
				height: calc(100vh - 85px) !important;
			}
		}
	}

	.pdf-signer-container {
		max-height: calc(100vh - 206px) !important;
		@media screen and (min-width: 1025px) {
			max-height: calc(100vh - 262px) !important;
		}
	}
}
