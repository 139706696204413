.kyc-events__wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	position: relative;
	padding: 16px;
	height: calc(-116px + 100vh);
	overflow: auto;
	width: 100%;

	&__inner_content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		overflow: scroll;
		height: calc(100% - 32px);
	}
	&__footer {
		position: fixed;
		bottom: 0px;
		height: 100px;
		width: 100%;
		display: grid;
		place-items: center;
		right: 0px;
		left: 0px;
	}
}
.date-info__full-datetime {
	font-weight: 600;
}
