// section {
// 	opacity: 1;
// 	transition: opacity 500ms ease-in-out;
// }

// header,
// footer {
// 	clear: both;
// }

// .removed {
// 	display: none;
// }

// .invisible {
// 	opacity: 0.2;
// }

// .note {
// 	font-style: italic;
// 	font-size: 130%;
// }

// .videoView,
// .detectOnClick,
// .blend-shapes {
// 	position: relative;
// 	float: left;
// 	width: 48%;
// 	height: 200vh;
// 	margin: 2% 1%;
// 	cursor: pointer;
// }

// .videoView p,
// .detectOnClick p {
// 	position: absolute;
// 	padding: 5px;
// 	background-color: #007f8b;
// 	color: #fff;
// 	border: 1px dashed rgba(255, 255, 255, 0.7);
// 	z-index: 2;
// 	font-size: 12px;
// 	margin: 0;
// }

// .highlighter {
// 	background: rgba(0, 255, 0, 0.25);
// 	border: 1px dashed #fff;
// 	z-index: 1;
// 	position: absolute;
// }

.canvas {
	z-index: 1;
	position: absolute;
	pointer-events: none;
}

.palm_canvas {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	@media only screen and (max-width: 500px) {
		-webkit-transform: rotateY(180deg) scaleX(-1);
	}
}

.hide-video-webcam__PalmEnroll {
	visibility: hidden;
}

.detectOnClick {
	z-index: 0;
}

.detectOnClick img {
	width: 100%;
}

.blend-shapes-item {
	display: flex;
	align-items: center;
	height: 20px;
}

.blend-shapes-label {
	display: flex;
	width: 120px;
	justify-content: flex-end;
	align-items: center;
	margin-right: 4px;
}

.blend-shapes-list {
	overflow: scroll;
	max-height: 500px;
	border: 1px solid black;
	padding: 20px 80px;
}

.blend-shapes-value {
	display: flex;
	height: 16px;
	align-items: center;
	background-color: #007f8b;
}

.palm-wrapper-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	.palm-video-camera {
		clear: both;
		display: block;
		position: absolute;
		transform: rotateY(180deg);
		-webkit-transform: rotateY(180deg);
		-moz-transform: rotateY(180deg);
		background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
		@media only screen and (max-width: 500px) {
			-webkit-transform: rotateY(180deg) scaleX(-1);
		}
	}
}

.palm-overlay-image {
	border-radius: 60px;
	position: absolute;
	top: 45%; //should update to 55
	left: 45%;
	transform: translate(-50%, -50%);
	width: 500px; /* Set a maximum width */
	height: 800px;
	// width: 100%; /* Set a maximum width */
	// height: auto;
	box-sizing: border-box; /* Make sure the border size is included in the width and height */
	z-index: 2;
	background: transparent;
	box-shadow: 0 0 0 99999rem rgba(0, 0, 0, 0.5);
	display: flex;
	flex-flow: column wrap;
	align-content: space-between;
	// border: 1px solid #000000a6;
	@media screen and (min-height: 800px) {
		border: none; /* Hide border if window height is more than 800px */
	}
	@media screen and (max-height: 650px) {
		border: none;
	}
	@media screen and (max-width: 1280px) and (min-width: 501px) {
		width: 400px;
		height: 650px;
	}
	@media screen and (max-width: 500px) {
		left: 50%;
		width: 400px;
		height: 700px;
	}
}

.palm-info-instructions {
	background: #0000003d;
	padding: 5px 10px 5px 10px;
	color: #fff;
	z-index: 999;
	/* Existing styles */
	position: absolute;
	bottom: calc(100vh - 70px); /* Position just above the overlay div */
	left: 35%;
	z-index: 2; /* Higher z-index to appear above .palm-overlayDiv */
	// display: none; //need to remove
	@media screen and (max-width: 1280px) {
		// left: 25%;
		top: 15%;
		bottom: unset;
		top: 5%;
	}
	@media screen and (max-width: 500px) {
		left: 8%;
		bottom: unset;
		top: 6%;
	}
}

.palm-info-instructions--countDown {
	left: 50%;
}

.palm-info-text__facial-enroll {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: var(--font-size-5);
	@media screen and (max-width: 1280px) {
		font-size: var(--font-size-3);
	}
	@media screen and (max-width: 500px) {
		font-size: var(--font-size-4);
	}
}

.palm-info-text__countDown {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 80px; // Adjust the font size as needed
	text-align: center;
	color: #ffffff; // Set the text color
	z-index: 999;
}

.rotate180 {
	transform: translate(-50%, -50%) scaleX(-1);
	left: 55%;

	@media screen and (max-width: 500px) {
		left: 50%;
	}
}
