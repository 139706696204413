.hider {
	position: absolute;
	// top: 0;
	width: 100%;
	height: 52px;
	background: var(--color-bg-100-light);
}
.relative {
	position: relative;
}

.kyc-loader-wrapper {
	position: absolute;
	inset: 0;
	display: grid;
	place-items: center;
}

.kyc-body-wrapper {
	&__inner {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		flex: 50% 50%;
	}
	&__right {
		width: 50%;
		@media screen and (max-width: 768px) {
      width: 100%;
		}
	}
	&__left {
		width: 50%;
		@media screen and (max-width: 768px) {
      width: 100%;
		}
	}
}
